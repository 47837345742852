import * as React from 'react';
import { useState } from 'react';
import Carousel, { Dots }  from '@brainhubeu/react-carousel';
import { HomeCarousel } from '../domain/interfaces.js';


const HomeCarouselPoster: React.FunctionComponent<{carousel: HomeCarousel}> = ({carousel}) => (
    <div className="w-full bg-cover bg-top h-64 md:h-128" dangerouslySetInnerHTML={{ __html: carousel.htmlBanner }} style={{'backgroundImage': `url(${carousel.imgUrl})`}} />
    
)

const HomeCarouselComponent: React.FunctionComponent<{carousels: HomeCarousel[]}> = ({carousels}) => {
    
    const [value, setValue] = useState<number>(0);
    const onChange = (value: number) => setValue(value)

    return (
        <div>
        <Carousel value={value} onChange={onChange} plugins={['arrows']}>
            {carousels.map((carousel) => <HomeCarouselPoster key={carousel.id} carousel={carousel} />)}
        </Carousel>
        <Dots value={value} number={3}  onChange={onChange} />
        </div>
    )

}


export default HomeCarouselComponent