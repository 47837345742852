import '@brainhubeu/react-carousel/lib/style.css'
import React, { useEffect, useState } from "react"
import { httpApiGateway } from '../adapters/secondary/httpEshopApi'
import BlogCardBanner from "../components/BlogCardBanner"
import Footer from "../components/Footer"
import HeaderMenu from "../components/Header"
import { Helmet } from "react-helmet"
import HomeCarousel from "../components/HomeCarousel"
import ProductMiniatureBanner from "../components/ProductMiniatureBanner"
import { Svg } from "../components/svgIcons"
import TestimonialCarousel from "../components/TestimonialCarousel"
import { Product, StaticContext } from "../domain/interfaces"

const ApiGateway = new httpApiGateway()

const HomePage: React.FunctionComponent<{pageContext: StaticContext}> = ({pageContext}) => {

    let [coupdecoeurproducts, setCoupDeCoeurProducts] = useState<Product[] | 'loading'>('loading')
    let [newproducts, setNewProducts] = useState<Product[] | 'loading'>('loading')
    useEffect(() => {
        ApiGateway.retrieveProducts(26).then(setCoupDeCoeurProducts)
        ApiGateway.retrieveProducts(27).then(setNewProducts)
    }, [])

    console.log(pageContext.categories)

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Chamantinis Bijoux en Papier</title>
                <meta name="description" content="Des bijoux uniques fabriqués à la Réunion. Des bijoux uniques pour des personnes uniques. Découvrez les différentes collections de colliers, boucles d'oreilles, bracelets etc.."></meta>
            </Helmet>
            <HeaderMenu contact={pageContext.contact} menu={pageContext.categories}/>
            <HomeCarousel carousels={pageContext.homeCarousels}/>

            <div className="w-full max-w-screen-lg flex flex-col md:flex-row mx-auto pt-5 mt-12">

                    <div className="w-full md:w-1/2 px-10">
                    <div className="text-3xl"> Des bijoux <span className="text-brand-primary"> uniques </span> fabriqués à la Réunion </div>
                    <img  className="w-72 mt-5 mb-2 rounded" src="/rainbow.JPG"/>
                    <div className="mt-5 md:mt-0 text-gray-800"> La réunion est une île riche en couleurs et en textures. Je m'inspire de sa généreuse nature dans mes créations.  </div>
                    </div>

                    <div className="w-full md:w-1/2 flex flex-col items-center justify-center px-10 mt-5 md:mt-0">
                        <img className="w-20 object-contain md:w-44 mx-auto pr-4" src="/reunion-island.png"/>
                        <span className="uppercase text-brand-gray-primary font-bold text-brand-primary pt-5"> La réunion </span>
                    </div>

            </div>

            <ProductMiniatureBanner banner={{title: "Les nouvelles collections", products: newproducts}}/>

            <div className="border-b-2 border-brand-primary text-center mt-20">
                <div className="mx-auto text-xl md:text-3xl font-bold text-brand-primary px-10"> Personnalisez vos bijoux </div>
                <div className="mx-auto text-md text-gray-800 mb-5 md:mb-0 px-5 mt-5 w-96"> 
                    Un bijou unique pour une personne unique. Je crée et personnalise les bijoux selon vos envies, contactez-moi pour les réaliser ensemble.  
                </div>
                <img className="md:hidden w-full opacity-50" src="/illu-tools@md.png"/>
                <img className="hidden md:block object-cover object-top w-full mx-auto -mt-20 opacity-50" src="/illu-tools@lg.png"/>
            </div>

            <div className="w-full max-w-screen-lg flex flex-col md:flex-row mx-auto mt-5">
                    <div className="w-full flex flex-row px-10 md:mt-0">
                        <div className="w-1/3 text-center">
                            <div className="cursor-pointer w-20 p-5 my-2 rounded-full border-2 border-brand-secondary mx-auto text-brand-secondary hover:text-white hover:bg-brand-primary"> <Svg icon={'credit-card'} /> </div>
                            <div className="w-full text-sm text-center text-brand-gray-light"> Paiement en ligne sécurisé </div>
                        </div>
                        <div className="w-1/3 text-center">
                            <div className="cursor-pointer w-20 p-5 my-2 rounded-full border-2 border-brand-secondary mx-auto text-brand-secondary hover:text-white hover:bg-brand-primary"> <Svg icon={'truck'} /> </div>
                            <div className="w-full text-sm text-center text-brand-gray-light"> <span className="font-bold text-brand-primary"> Livraison  Gratuite à la Réunion  </span> </div>
                        </div>
                        <div className="w-1/3 text-center">
                            <div className="cursor-pointer w-20 p-5 my-2 rounded-full border-2 border-brand-secondary mx-auto text-brand-secondary hover:text-white hover:bg-brand-primary"> <Svg icon={'globe'} /> </div>
                            <div className="w-full text-sm text-center text-brand-gray-light"> Livraison dans le monde </div>
                        </div>
                    </div>
            </div>

            <ProductMiniatureBanner banner={{titleHtml: "Mes coups de <span class='text-brand-primary'>♥️</span>", products: coupdecoeurproducts}}/>
            
            <TestimonialCarousel 
                backgroundUrl={"/chamantini/photos/cover_picture_1_blured.png"}
                testimonials={[{
                    imgUrl: '/chamantini/photos/corinne.png',
                    title: 'Corinne Chamant',
                    subtitle: 'Créatrice bijoux et couture',
                    text: 'Je m’appelle Corinne et je suis Créatrice autodidacte de CHAMANTINI’S PAPER BEADS. Originaire de l’île de la Réunion, je suis également maman de 4 filles, pour lesquelles je crée des vêtements, divers accessoires et bijoux, à base de matériaux neufs ou recyclés depuis leur plus jeune âge.'
                }]}
            />

            <BlogCardBanner banner={{title: "Nos derniers articles", cards: pageContext.blogPosts}} />
            <Footer contact={pageContext.contact} group1={pageContext.footer.group1} group2={pageContext.footer.group2} group3={pageContext.footer.group3} />            
        </div>
    )

}

export default HomePage