import * as React from 'react';
import { useState } from 'react';
import Carousel, { Dots }  from '@brainhubeu/react-carousel';
import { HomeCarousel } from '../domain/interfaces.js';

interface Testimony {
    imgUrl: string,
    title: string,
    subtitle: string,
    text: string
}

const TestimonyComponent: React.FunctionComponent<{testimony: Testimony}> = ({testimony}) => (
    <div className="w-full max-w-screen-lg mx-auto px-10 pt-16">
      <div className="w-full bg-white bg-opacity-80 rounded md:h-48 mb-16">
        <div className="w-full flex flex-row ">
            <div className="w-1/3 flex justify-center items-center">
              <img className="rounded-full w-20 md:w-64 h-20 md:h-64 -mt-8 ring-4 ring-gray-200 shadow-xl" src={testimony.imgUrl} />
            </div>
            <div className="md:hidden w-2/3 flex flex-col py-4 pl-4">
              <div className="text-xl font-bold text-brand-primary"> {testimony.title} </div>
              <div className="text-sm text-brand-primary"> {testimony.subtitle} </div>
            </div> 
            <div className="hidden md:block w-2/3 flex flex-col py-4 px-10">
              <div className="text-2xl font-bold text-brand-primary"> {testimony.title} </div>
              <div className="text-sm text-brand-primary"> {testimony.subtitle} </div>
              <div className="text-brand-primary-darkest mt-2 text-sm"> {testimony.text} </div>
            </div> 
        </div>
        <div className="md:hidden w-full flex flex-row">
            <div className="w-full flex flex-col pb-4 px-10">
              <div className="text-brand-primary-darkest lg:mt-2 text-sm"> {testimony.text} </div>
            </div> 
        </div>
      </div>
  </div>
)

const TestimonialsCarouselWrapper: React.FunctionComponent<{children: React.ReactNode, backgroundUrl: string}> = ({children, backgroundUrl}) => (
    <div className="w-full bg-cover bg-top bg-local h-96 pb-20 mt-20" style={{'backgroundImage': `url(${backgroundUrl})`}} >
        {children}
    </div>  
)

const TestimonialsCarousel: React.FunctionComponent<{testimonials: Testimony[], backgroundUrl: string}> = ({testimonials, backgroundUrl}) => {
    
    const [value, setValue] = useState<number>(0);
    const onChange = (value: number) => setValue(value)

    const testimonialsElements = testimonials.map(testimony => <TestimonyComponent key={testimony.title} testimony={testimony} />)
    const countTestimoniales = testimonials.length
  
    return (
        <TestimonialsCarouselWrapper backgroundUrl={backgroundUrl}>
            <Carousel value={value} onChange={onChange} plugins={['arrows']}>
                {testimonialsElements}
            </Carousel>
            <Dots value={value} number={countTestimoniales}  onChange={onChange} />
        </TestimonialsCarouselWrapper>
    )

}


export default TestimonialsCarousel